import React from "react"
import { graphql, StaticQuery } from "gatsby";
import { inject } from "mobx-react";
import { VisitorStore } from "stores";
import { PAGE_NOT_FOUND_EVENT } from "../utils/constants";

@inject("visitorStore")
export default class PageNotFoundPage extends React.Component<any> {
  componentWillMount() {
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(PAGE_NOT_FOUND_EVENT, this.props.location);
  }
  render() {
    return (
      <StaticQuery query={graphql`
      {
        page: squidex404Page(identity: { eq: "404page"}) {
          title {
            de
            nl
            en
          }
          text {
            de {
              html
            }
            nl {
              html
            }
            en {
              html
            }
          }
        }
      }`} render={(data) => (
          <div>
            <h1>{data.page.title[process.env.GATSBY_LANGUAGE]}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.page.text[process.env.GATSBY_LANGUAGE].html }} />
          </div>
        )} />)
  }
}
